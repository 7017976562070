import { TranslationKeys } from '~/i18n/TranslationKeys'

export const weekDaysShort = [
	TranslationKeys.SUNDAY_SHORT,
	TranslationKeys.MONDAY_SHORT,
	TranslationKeys.TUESDAY_SHORT,
	TranslationKeys.WEDNESDAY_SHORT,
	TranslationKeys.THURSDAY_SHORT,
	TranslationKeys.FRIDAY_SHORT,
	TranslationKeys.SATURDAY_SHORT,
]

export const weekDaysMobile = [
	TranslationKeys.SUNDAY_MOBILE,
	TranslationKeys.MONDAY_MOBILE,
	TranslationKeys.TUESDAY_MOBILE,
	TranslationKeys.WEDNESDAY_MOBILE,
	TranslationKeys.THURSDAY_MOBILE,
	TranslationKeys.FRIDAY_MOBILE,
	TranslationKeys.SATURDAY_MOBILE,
]
